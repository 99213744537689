<template>
  <div class="furtherinformation">
    <van-nav-bar
      :title="$t('FURTHERINFORMATION')"
      @click-left="onClickLeft"
    >
    <van-icon color="#fff" size="25" slot="left" name="arrow-left" />
    </van-nav-bar>
    <div class="main">{{$t('WORKCERTIFICATE')}}</div>
    <!-- 文件上传一 -->
    <div class="IDUploadbox">
      <van-uploader v-if="BUploader"  class="Uploader" :after-read="afterReadOnTheBack" >
        <div class="UploadArea">
          <div class="cameraBox">
            <van-image class="cameraImg" :src="imgurl" />
          </div>
          <div class="UploadInstructions">{{$t('WORKCERTIFICATE')}}</div>
        </div>
      </van-uploader>
      <div v-else class="showimg">
        <div @click="removeB" class="del">X</div>
        <img @click="click_img_one" class="img" :src="imgUrlB" >
      </div>
    </div>
    <div class="main">{{$t('INCOMEPROOF')}}</div>
    <!-- 文件上传二 -->
    <div class="IDUploadbox">
      <van-uploader v-if="CUploader" class="Uploader" :after-read="afterReadHand">
        <div class="UploadArea">
          <div class="cameraBox">
            <van-image class="cameraImg" :src="imgurl" />
          </div>
          <div class="UploadInstructions">{{$t('INCOMEPROOF')}}</div>
        </div>
      </van-uploader>
      <div v-else class="showimg">
        <div @click="removeC" class="del">X</div>
        <img @click="click_img_two" class="img" :src="imgUrlC" >
      </div>
    </div>
    <div class="subbox">
      <van-button :disabled="stopSub" @click="subs" class="sub" color="#0097B2" type="primary" block>{{$t('SUBMIT')}}</van-button>
    </div>
  </div>
</template>

<script>
import { uploadPhoto } from '@/api/wallet'
import { saveProofIncome, myInformation, getProofIncome } from '@/api/my'
import './css/my.css'
import { Toast, ImagePreview } from 'vant'
export default {
  name: 'furtherinformation',
  data () {
    return {
      imgUrlB: '',
      imgUrlC: '',
      BUploader: true,
      CUploader: true,
      stopSub: false,
      imgurl: require('../Login/img/logo.png')
    }
  },
  created () {
    this.getmyInformation()
    this.loadgetProofIncome()
  },
  methods: {
    onClickLeft () {
      this.$router.go(-1)
    },
    removeB () {
      this.BUploader = true
      this.imgUrlB = null
    },
    removeC () {
      this.CUploader = true
      this.imgUrlC = null
    },
    async afterReadOnTheBack (file) {
      const formData = new FormData()
      formData.append('picture', file.file)
      const { data } = await uploadPhoto(formData)
      if (data.code === 200) {
        this.imgUrlB = 'http://' + data.data
        this.BUploader = false
      }
      if (data.code === 500) {
        Toast(data.message)
      }
    },
    async afterReadHand (file) {
      const formData = new FormData()
      formData.append('picture', file.file)
      const { data } = await uploadPhoto(formData)
      if (data.code === 200) {
        this.imgUrlC = 'http://' + data.data
        this.CUploader = false
      }
      if (data.code === 500) {
        Toast(data.message)
      }
    },
    async subs () {
      const { data } = await saveProofIncome({
        incomeProofUrl: this.imgUrlC,
        workProofUrl: this.imgUrlB
      })
      if (data.code === 200) {
        this.stopSub = true
        Toast(this.$t('SUBMITSUCCEED'))
      }
      if (data.code === 500) {
        Toast(data.message)
      }
    },
    async getmyInformation () {
      const { data } = await myInformation()
      if (data.data.workProof) {
        this.stopSub = true
      } else {
        this.stopSub = false
      }
    },
    async loadgetProofIncome () {
      const { data } = await getProofIncome()
      if (data.code === 200 && data.data.workProofUrl && data.data.incomeProofUrl) {
        this.imgUrlB = data.data.workProofUrl
        this.imgUrlC = data.data.incomeProofUrl
        this.BUploader = false
        this.CUploader = false
      }
    },
    // 图片预览一
    click_img_one () {
      if (this.imgUrlB === '') return false
      ImagePreview([this.imgUrlB])
    },
    // 图片预览二
    click_img_two () {
      if (this.imgUrlC === '') return false
      ImagePreview([this.imgUrlC])
    }
  }
}
</script>

<style scoped>
.img {
  width: 100%;
  height: 100%;
}
.del {
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 30px;
  background: rgba(0, 0, 0, 0.5);
  border-bottom-left-radius: 50px;
  text-align: center;
  color: #fff;
  font-size: 15px;
  line-height: 30px;
  text-indent: .5em;
}
.showimg {
  position: relative;
  width: 100%;
  height: 100%;
}
.IDUploadbox {
  box-sizing: border-box;
  padding: 3px;
  height: 200px;
  background-color: #fff;
}
.UploadArea {
  width: 367px;
  height: 190px;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
}
.cameraBox {
  margin: 0 auto;
  margin-top: 40px;
  width: 70px;
  height: 70px;
}
.cameraImg {
  width: 100%;
  height: 100%;
}
.UploadInstructions {
  margin: 0 auto;
  width: 150px;
  height: 28px;
  text-align: center;
  line-height: 28px;
  color: #fff;
  font-size: 14px;
  background: rgb(63, 153, 243);
  opacity: .5;
  padding: 0 9px;
  margin-top: 20px;
  border-radius: 8px;
}
.main {
  font-size: 20px;
  box-sizing: border-box;
  padding: 20px;
  border-bottom: 1px solid #e4e4e4;
  background-color: #fff;
}
.subbox {
  padding: 20px;
  background-color: #fff;
}
.borderb {
  border-bottom: 1px solid #e4e4e4;
}
.w44 {
  width: 332.3px;
}
.sub {
  font-size: 18px;
  border-radius: 4px;
}
</style>
